/* styles.css */

/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Import Fonts (Add this to public/index.html) */
  /* 
  <link href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Press+Start+2P&display=swap" rel="stylesheet">
  */
  
  /* Apply Orbitron to body text */
  body, html {
    height: 100%;
    font-family: 'Orbitron', sans-serif;
    background: #0f0f0fd6; /* Fallback background color */
    color: #fff;
    overflow: hidden;
    position: relative; /* Necessary for positioning pseudo-elements */
  }
  
  /* Apply Press Start 2P to headings */
  h1, h2, .game-subtitle, .content-block h2 {
    font-family: 'Press Start 2P', cursive;
  }
  
  /* App Container */
  .App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    position: relative;
  }
  
  /* Main Content */
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Game Title Image with Neon Glow */
  .game-title-image {
    width: 500px; /* Fixed width */
    height: auto;
    margin-bottom: -70px;
    filter: drop-shadow(0 0 20px #ff00ff); /* Neon pink glow */
  }
  
  .game-title-image:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
  }
  
  /* Subtitle Text */
  .game-subtitle {
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  /* Play Button */
  .play-button {
    background: #000;
    color: #0aff0a; /* Bright green */
    padding: 20px 60px;
    font-size: 18px;
    margin: 20px;
    cursor: pointer;
    border: 4px solid #0aff0a;
    font-family: 'Press Start 2P', cursive;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .play-button:hover {
    background: #0aff0a;
    color: #000;
  }
  
  /* Info Button */
  .info-button {
    background: #000;
    color: #ff0000; /* Bright red */
    padding: 15px 50px;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border: 4px solid #ff0000;
    font-family: 'Press Start 2P', cursive;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .info-button:hover {
    background: #ff0000;
    color: #000;
  }
  
  /* Icons inside Buttons */
  .play-button svg, .info-button svg {
    margin-right: 10px;
    vertical-align: middle;
  }
  
  /* Mute Button */
  .mute-button {
    position: fixed;
    top: 20px;
    left: 20px;
    background: #000;
    border: 2px solid #fff;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    padding: 10px 10px 5px 10px;
    border-radius: 50%;
    transition: background 0.3s, color 0.3s;
  }
  
  .mute-button:hover {
    background: #fff;
    color: #000;
  }
  
  /* Modal Styles */
  .modal {
    background: #1a1a1a;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    margin: 20px auto; /* Adjusted margins */
    position: relative;
    border-radius: 10px;
    outline: none;
    color: #fff;
    max-height: calc(100vh - 40px); /* Modal height adjusts to viewport */
    overflow-y: auto; /* Enable scrolling within the modal */
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Allow scrolling if content overflows */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Close Button */
  .close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 36px;
    position: absolute; /* Keep it within the modal */
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
  }
  
  /* Modal Content */
  .modal-content {
    margin-top: 50px; /* Space below the close button */
    text-align: center;
  }
  
  /* Ensure modal content doesn't get hidden behind the close button */
@media (max-width: 768px) {
    .modal-content {
      margin-top: 60px;
    }
  }
  /* Content Block */
  .content-block {
    margin-bottom: 30px;
  }
  
  .content-block h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .content-block p {
    font-size: 18px;
    line-height: 1.5;
  }
  
  /* Center images with Neon Glow */
  .content-block img {
    width: 100px; /* Fixed width */
    height: auto;
    margin: 0 auto 10px auto;
    display: block;
    filter: drop-shadow(0 0 20px #00ffff); /* Neon cyan glow */
  }
  
  .content-block img:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
  }
  
  /* Features List */
  .features-list {
    list-style-type: none;
    padding: 0;
    font-family: 'Orbitron', sans-serif;
  }
  
  .features-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .features-list li::before {
    content: '★'; /* Star symbol */
    position: absolute;
    left: 0;
    color: #ffcc00; /* Gold color */
    font-size: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .game-title-image {
      width: 300px; /* Adjusted width for smaller screens */
    }
  
    .game-subtitle {
      margin-top: 15px;
      font-size: 15px;
    }
  
    .play-button {
      padding: 15px 40px;
      font-size: 14px;
    }
  
    .info-button {
      padding: 12px 35px;
      font-size: 12px;
    }
  }
  
  /* Glowing Text Animation */
  @keyframes glow {
    0% {
      text-shadow: 0 0 5px #fff, 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 40px #ff00ff, 0 0 80px #ff00ff;
    }
    50% {
      text-shadow: 0 0 10px #fff, 0 0 20px #00ffff, 0 0 30px #00ffff, 0 0 50px #00ffff, 0 0 100px #00ffff;
    }
    100% {
      text-shadow: 0 0 5px #fff, 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 40px #ff00ff, 0 0 80px #ff00ff;
    }
  }
  
  .game-title {
    animation: glow 2s infinite;
  }
  
  
  /* Background Image */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/background.webp') no-repeat center center fixed;
  background-size: cover;
  filter: brightness(50%);
  z-index: -2;
}


/* Flying "kirt.png" Images */
.flying-kirt {
  position: fixed;
  width: 150px;
  height: 150px;
  background: url('./assets/kirt.png') no-repeat;
  background-size: contain;
  animation: flyby 10s linear infinite;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

/* Flyby Animation */
@keyframes flyby {
  0% {
    transform: translateX(0); /* Start off-screen */
    opacity: 0;
  }
  10% {
    opacity: 1; /* Fade in */
  }
  50% {
    transform: translateX(120vw); /* Fly across the screen horizontally */
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(200vw); /* Fly off the screen horizontally */
    opacity: 0;
  }
}


  